import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";

const IGNORED_ENDPOINTS = ["api/users/me"];

@Injectable()
export class AnonUserInterceptor implements HttpInterceptor {
    constructor(private route: ActivatedRoute) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const params = this.route.snapshot.queryParams;
        const isIgnored = IGNORED_ENDPOINTS.some((endpoint) =>
            req.url.startsWith(endpoint),
        );
        if (params.anon === "true" && !isIgnored) {
            req = req.clone({
                setParams: {
                    anon: "true",
                },
            });
        }
        return next.handle(req);
    }
}
