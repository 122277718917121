import {
    PreloadAllModules,
    RouterModule,
    RouterOutlet,
    Routes,
} from "@angular/router";
import { NoContentComponent } from "./components/no-content";
import { NgModule } from "@angular/core";
import { AuthGuard, OnboardingGuard } from "./_guards";
import { SubscriptionGuard } from "@/_guards/subscription-guard.service";
import { AnonLandingComponent } from "@/components/anon-landing/anon-landing.component";
import { PlaygroundComponent } from "@/components/playground/playground.component";
import { RedirectGuard } from "@/_guards/redirect.guard";

export const ROUTES: Routes = [
    {
        path: "",
        pathMatch: "full",
        canActivate: [AuthGuard, OnboardingGuard, RedirectGuard],
        children: [],
    },
    {
        path: "playground",
        component: PlaygroundComponent,
    },
    {
        path: "anon-landing-page",
        component: AnonLandingComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "reports",
        loadChildren: () =>
            import("./components/frat/frat.module").then((m) => m.FratModule),
        canActivate: [AuthGuard, SubscriptionGuard],
    },
    {
        path: "", // full path delegated to module routes
        loadChildren: () =>
            import("./components/welcome/welcome.module").then(
                (m) => m.WelcomeModule,
            ),
    },
    {
        path: "",
        loadChildren: () =>
            import("@/components/pages/pages.module").then(
                (m) => m.PagesModule,
            ),
        canActivate: [AuthGuard, OnboardingGuard],
    },
    // *** Add All Routes Above Here ***
    {
        path: "**",
        component: NoContentComponent,
    },
];

@NgModule({
    imports: [
        RouterModule,
        RouterOutlet,
        RouterModule.forRoot(ROUTES, {
            preloadingStrategy: PreloadAllModules,
            paramsInheritanceStrategy: "always",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
