import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "@/_services";

import * as _ from "lodash";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout().subscribe({
                        next: () => location.reload(),
                    });
                }

                const multipleErrors = _.get(err, "error.errors", []);
                if (multipleErrors.length > 0) {
                    return throwError(err.error);
                }

                if (err && err.error && err.error.message) {
                    return throwError(err.error.message);
                }
                if (err.statusText) {
                    return throwError(err.statusText);
                }
                return throwError(err);
            }),
        );
    }
}
