import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-anon-landing",
    templateUrl: "./anon-landing.component.html",
    styleUrls: ["./anon-landing.component.scss"],
})
export class AnonLandingComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        /*
            This component is a hack.
            It exists only to enforce that AuthGuard runs first and successfully checks the current login status,
            OR requests a login for the anonymous user with a token.
            After that, we're free to redirect elsewhere in the app that might have other guards.

            There is a race condition between AuthGuard and Onboarding guard.
            If the AuthGuard sends its request first to login as the anon user, we proceed to the flight details screen.
            If OnboardingGuard calls /users/me first, then a 401 will be returned, and we kick the user to the login screen.
            Angular 8 doesn't have the ability to enforce sequential execution of async guards.
            A better solution would be to use functional guards available in Angular 15+
            https://dev.to/dzinxed/a-new-way-of-ordering-guards-in-angular-24do
         */
        const assessmentId = this.route.snapshot.queryParams["assessmentId"];
        this.router.navigate(["tasks", "createFlight"], {
            queryParams: {
                assessmentId,
            },
        });
    }
}
