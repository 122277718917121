import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { NoContentComponent } from "./components/no-content";
import { AppRoutingModule } from "./app.routes";
import { FlightModule } from "./components/flight/flight.module";
import { FratModule } from "./components/frat/frat.module";
import {
    AuthenticationService,
    FORM_FILL_PARAMS,
    formFillParamsFactory,
    FormHelperService,
    UserIsAtLeastAdminProvider,
    UserIsOwnerProvider,
    UserService,
} from "./_services";
import { NavbarModule } from "./components/navbar/navbar.module";
import { UserModule } from "./components/user/user.module";
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { ErrorInterceptor, JwtInterceptor } from "./interceptors";
import { AdminGuard, AuthGuard } from "./_guards";
import { SharedComponentsModule } from "./components/shared/shared-components.module";
import { ToastContainerDirective, ToastrModule } from "ngx-toastr";
import { AssessmentModule } from "@/components/assessment/assessment.module";
import { PagesModule } from "@/components/pages/pages.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { AnonUserInterceptor } from "@/interceptors/AnonUserInterceptor";
import { AnonLandingComponent } from "./components/anon-landing/anon-landing.component";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

// Application wide providers
const AppProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AnonUserInterceptor, multi: true },
];

function initializeApp() {
    (window as any).pdfWorkerSrc = "/assets/pdfjs/pdf.worker.js";
    console.log("pdf worker url set");
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, NoContentComponent, AnonLandingComponent],
    imports: [
        // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        FlightModule,
        FratModule,
        UserModule,
        ReactiveFormsModule,
        NavbarModule,
        SharedComponentsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-left",
            toastClass: "push-notice",
            maxOpened: 1,
            tapToDismiss: false,
            preventDuplicates: true,
            autoDismiss: false,
        }),
        ToastContainerDirective,
        AssessmentModule,
        PagesModule,
        MatExpansionModule,
    ],
    providers: [
        AppProviders,
        AuthenticationService,
        FormHelperService,
        UserService,
        AuthGuard,
        AdminGuard,
        UserIsAtLeastAdminProvider,
        UserIsOwnerProvider,
        {
            provide: FORM_FILL_PARAMS,
            useFactory: formFillParamsFactory,
            deps: [UserService],
        },
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useValue: initializeApp,
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
    ],
})
export class AppModule {}
