import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "@/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";

if (environment.production) {
    Sentry.init({
        dsn: "https://199f8aa6d605637408f7bbd10e1e2a83@o4508288222101504.ingest.us.sentry.io/4508321970126848",
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.25,
        environment: environment.sentryEnvironmentName,
        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
            /https:\/\/(?:dev\.)?app\.preflightmitigator\.com\/api/,
        ],
    });
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
