import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";

@Component({
    selector: "app-root",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["app.component.scss", "../assets/css/core.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    title = "Preflight";

    @ViewChild(ToastContainerDirective, { static: true })
    toastContainer: ToastContainerDirective;

    constructor(
        private router: Router,
        private toastService: ToastrService,
    ) {}

    ngOnInit() {
        this.toastService.overlayContainer = this.toastContainer;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (document.scrollingElement) {
                    document.scrollingElement.scrollTop = 0;
                }
            }
        });
    }
}
