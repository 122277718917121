<div class="p-4">
    <h1>PFM buttons</h1>
    <div class="flex justify-content-between">

        <button class="btn btn-primary">Primary</button>

        <button class="btn btn-secondary">Secondary</button>

        <button class="btn btn-tertiary">Tertiary</button>

        <button class="btn btn-quaternary">Quaternary</button>


    </div>

    <h1>PrimeNG buttons</h1>
    <div class="flex justify-content-between">
        <p-button label="Primary" />
        <p-button label="Primary" icon="pi pi-plus" />
        <p-button label="Primary" icon="pi pi-plus" iconPos="right" />
        <p-button icon="pi pi-plus" />
    </div><br>
    <div class="flex justify-content-between">
        <p-button label="Secondary" severity="secondary" />
        <p-button label="Secondary" severity="secondary" icon="pi pi-plus" />
        <p-button label="Secondary" severity="secondary" icon="pi pi-plus" iconPos="right" />
        <p-button severity="secondary" icon="pi pi-plus" />
    </div><br>
    <div class="flex justify-content-between">
        <p-button label="Text" [text]="true" />
        <p-button label="Text" [text]="true" icon="pi pi-plus" />
        <p-button label="Text" [text]="true" icon="pi pi-plus" iconPos="right" />
        <p-button [text]="true" icon="pi pi-plus" />
    </div><br>
    <div class="flex justify-content-between">
        <p-button label="Success" severity="success" />
        <p-button label="Info" severity="info" />
        <p-button label="Warning" severity="warning" />
<!--
        <p-button label="Help" severity="help" />
-->
        <p-button label="Danger" severity="danger" />
        <p-button label="Contrast" severity="contrast" />
    </div>


    <h1>Form widgets</h1>

    <div class="grid justify-content-around">


        <div class="col-2">
            <app-checkbox label="app-checkbox" [formControl]="checkboxControl"></app-checkbox>

        </div>

        <div class="col-2">
            <div class="field-checkbox">
                <input type="checkbox" id="regular-checkbox" [formControl]="checkboxControl">
                <label for="regular-checkbox">Regular Checkbox</label>

            </div>
        </div>

        <div class="col-2">
            <div class="field-checkbox">
                <p-checkbox id="p-checkbox" [binary]="true" [formControl]="checkboxControl"></p-checkbox>
                <label for="p-checkbox">Prime checkbox</label>
            </div>
        </div>

        <div class="col-2">

            <div class="field-checkbox">

                <p-inputSwitch id="p-switch" [formControl]="checkboxControl" />
                <label for="p-switch">Prime Switch</label>
            </div>
        </div>

        <div class="col-2">
            <div class="field-checkbox">
                <app-input-switch label="app-input-switch" [control]="checkboxControl"></app-input-switch>
            </div>
        </div>


    </div>


    <h1>Toasts</h1>
    <div class="flex justify-content-around">

        <p-button label="Show old toast" (onClick)="showToastr()"></p-button>

        <p-splitButton label="Show PrimeNG toast" [model]="toastOptions" (onClick)="showToast()"></p-splitButton>

        <p-toast position="bottom-left"></p-toast>
    </div>


    <h1>Inputs</h1>
    <div class="grid">
        <div class="col">
            <p-iconField iconPosition="right">
                <input type="text" pInputText placeholder="Search" [formControl]="searchControl" />
                <p-inputIcon styleClass="pi pi-times" (click)="clearSearch()" />
            </p-iconField>
        </div>
    </div>
</div>
