import { Component } from "@angular/core";
import { Button } from "primeng/button";
import { SharedComponentsModule } from "@/components/shared/shared-components.module";
import { InputSwitchModule } from "primeng/inputswitch";
import { CheckboxModule } from "primeng/checkbox";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MenuItem, MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { SplitButtonModule } from "primeng/splitbutton";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { InputTextModule } from "primeng/inputtext";

@Component({
    selector: "app-playground",
    standalone: true,
    imports: [
        Button,
        SharedComponentsModule,
        InputSwitchModule,
        CheckboxModule,
        ReactiveFormsModule,
        ToastModule,
        SplitButtonModule,
        IconFieldModule,
        InputIconModule,
        InputTextModule,
    ],
    providers: [MessageService, ToastrModule],
    templateUrl: "./playground.component.html",
    styleUrl: "./playground.component.scss",
})
export class PlaygroundComponent {
    toastOptions: MenuItem[] = [
        {
            label: "Default Toast",
            command: () => this.showToast("info"),
        },
        {
            label: "Warning toast",
            command: () => this.showToast("warn"),
        },
        {
            label: "Error Toast",
            command: () => this.showToast("error"),
        },
    ];
    constructor(
        private messageService: MessageService,
        private toastr: ToastrService,
    ) {}

    checkboxControl = new FormControl<boolean>(false);
    searchControl: FormControl = new FormControl<string>("");

    showToast(level: string = "info") {
        this.messageService.add({
            severity: level,
            summary: "Success",
            detail: "An example message was added to the playground.",
            sticky: true,
        });
    }

    showToastr() {
        this.toastr.success("Example toastr message", "Success", {
            disableTimeOut: true,
        });
    }

    clearSearch() {
        this.searchControl.patchValue("");
    }
}
