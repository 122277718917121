import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    templateUrl: "no-content.component.html",
    styleUrls: ["./no-content.component.scss"],
})
export class NoContentComponent {
    constructor(private router: Router) {}
    flights() {
        //this.router.navigate(['/'], { });
        this.router.navigateByUrl("/");
    }
    // login() {
    //   this.router.navigateByUrl('/')
    // }
}

// import { Component } from '@angular/core';

// @Component({
//   selector: 'no-content',
//   template: `
//     <div>
//       <h1>404: page missing</h1>
//     </div>
//   `,
//   styles: `
//   `
// })
// export class NoContentComponent {

// }
